import { Fragment, useState, useEffect } from "react";
import { useUnityContextProvider } from "./UnityContext";
import { uploadFileToS3 } from '../utils/S3Utils';
import { getDateTimeNowWithUnderscore } from '../utils/Helper';

const ErrorReport = () => {
    const [errorLog, setErrorLog] = useState("Starting error log...");
    const { addEventListener, removeEventListener } = useUnityContextProvider();

    // Should send the report to a remote server
    const sendErrorReport = async () => {
        // Use window.prompt to get user comments
        const userComments = window.prompt("Please describe the error:", "");
        if (userComments === null) {
            // User canceled the prompt
            return;
        }

        const error_date = getDateTimeNowWithUnderscore();
        const combinedErrorLog = `${errorLog}\n\nUser Comments: ${userComments}`;
        const error_file = new File([new Blob([combinedErrorLog])], error_date + ".txt");
        await uploadFileToS3(error_file, "error-reports/");
        window.alert("Error report sent");
    };

    const handleErrorReportUpdate = (error) => {
        setErrorLog(error);
    };

    useEffect(() => {
        addEventListener("UpdateErrorReport", handleErrorReportUpdate);
        return () => {
            removeEventListener("UpdateErrorReport", handleErrorReportUpdate);
        };
    }, [errorLog, addEventListener, removeEventListener]);

    return (
        <Fragment>
            <button
                id="unity-fullscreen-button"
                onClick={sendErrorReport}
                style={{
                    cursor: 'pointer',
                    float: 'left',
                    width: '38px',
                    height: '38px',
                    background: `url(${process.env.PUBLIC_URL}/error-icon.png) no-repeat center`,
                    border: 'none',
                }}
            />
        </Fragment>
    );
};

export default ErrorReport;
