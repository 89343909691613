import { useCallback, useEffect } from 'react';
import { useUnityContextProvider } from './UnityContext';
import { useState } from 'react';

const AutoSaveService = () => {
    const [saveExists, setSaveExists] = useState(false);
    const [showContinuePrompt, setShowContinuePrompt] = useState(false);
    const { sendMessage, addEventListener, removeEventListener } = useUnityContextProvider();

    const dbName = "UnityGameDB";
    const storeName = "GameSaveStore";
    const saveName = "autosave";

    // Function to save data to IndexedDB
// Function to save data to IndexedDB
const saveToIndexedDB = async (key, value) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, 1);

        request.onerror = () => {
            console.error("IndexedDB: Failed to open database.");
            reject("Failed to open database.");
        };

        request.onupgradeneeded = (event) => {
            console.log("Creating new object store in IndexedDB...");
            const db = event.target.result;
            db.createObjectStore(storeName); // Create the object store during the upgrade
        };

        request.onsuccess = () => {
            const db = request.result;

            // Check if the object store exists, if not delete the database and recreate
            if (!db.objectStoreNames.contains(storeName)) {
                console.log("Object store not found, recreating database...");
                db.close();
                indexedDB.deleteDatabase(dbName); // Delete the current database

                // Re-open and create object store again
                const recreateRequest = indexedDB.open(dbName, 1);
                
                recreateRequest.onupgradeneeded = () => {
                    console.log("Recreating object store...");
                    const recreateDb = recreateRequest.result;
                    recreateDb.createObjectStore(storeName);
                };

                recreateRequest.onsuccess = () => {
                    const newDb = recreateRequest.result;
                    const tx = newDb.transaction(storeName, 'readwrite');
                    const store = tx.objectStore(storeName);
                    store.put(value, key);
                    tx.oncomplete = () => {
                        console.log("Data successfully saved to IndexedDB.");
                        resolve();
                    };
                    tx.onerror = () => {
                        console.error("IndexedDB: Failed to save data after recreation.");
                        reject("Failed to save data after recreation.");
                    };
                };

                recreateRequest.onerror = () => {
                    console.error("Failed to recreate database.");
                    reject("Failed to recreate database.");
                };
            } else {
                // Proceed as normal if the object store exists
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);
                store.put(value, key);
                tx.oncomplete = () => {
                    console.log("Data successfully saved to IndexedDB.");
                    resolve();
                };
                tx.onerror = () => {
                    console.error("IndexedDB: Failed to save data.");
                    reject("Failed to save data.");
                };
            }
        };
    });
};


    const checkSaveExists = async () => {
        try {
            const dbRequest = indexedDB.open(dbName, 1);
            dbRequest.onsuccess = function() { // Check if the save file exists in the database
                try {
                    const db = dbRequest.result;
                    const tx = db.transaction(storeName, "readonly");
                    const store = tx.objectStore(storeName);
                    const getRequest = store.get(saveName);
                    getRequest.onsuccess = function() {
                        console.log("Save file found in IndexedDB.");
                        if (getRequest.result) {
                            setSaveExists(true);
                            setShowContinuePrompt(true);
                        }
                    };

                    console.log("Save file not found in IndexedDB.");
                }
                catch (error) {
                    console.log("No save file found.");
                }
            };
        } catch (error) {
            console.error("Error checking save existence:", error);
        }
    };

    // Handle user response to continue from the save
    const handleUserResponse = async (continueFromSave) => {
        if (continueFromSave) {
            const saveData = await getSaveData();
            const chunkSize = 100000;
            let currentIndex = 0;

            // Send the save data in chunks to avoid exceeding the message size limit
            while (currentIndex + chunkSize < saveData.length) {
                sendMessage('SaveLoadManager', 'AutoSaveDataChunk', saveData.substring(currentIndex, currentIndex + chunkSize));
                currentIndex += chunkSize;
            }

            // Send last chunk
            sendMessage('SaveLoadManager', 'AutoSaveDataChunk', saveData.substring(currentIndex, saveData.length));
            sendMessage('SaveLoadManager', 'LoadAutoSave'); // Send a message to Unity to load the saved game

        } else {
            await clearSaveData(); // Clear the save data if user opts to start a new game
            console.log("Starting a new game...");
        }
        setShowContinuePrompt(false);
    };

    // Function to retrieve the saved game data from IndexedDB
    const getSaveData = async () => {
        return new Promise((resolve, reject) => {
            const dbRequest = indexedDB.open(dbName, 1);
            dbRequest.onsuccess = function() {
                const db = dbRequest.result;
                const tx = db.transaction(storeName, "readonly");
                const store = tx.objectStore(storeName);
                const getRequest = store.get(saveName);
                getRequest.onsuccess = function() {
                    if (getRequest.result) {
                        resolve(getRequest.result);
                    } else {
                        resolve(null); // No saved data found
                    }
                };
                getRequest.onerror = function() {
                    reject("Failed to retrieve save data.");
                };
            };
        });
    };

    // Function to clear the save data from IndexedDB
    const clearSaveData = async () => {
        return new Promise((resolve, reject) => {
            const dbRequest = indexedDB.open(dbName, 1);
            dbRequest.onsuccess = function() {
                const db = dbRequest.result;
                const tx = db.transaction(storeName, "readwrite");
                const store = tx.objectStore(storeName);
                const deleteRequest = store.delete(saveName);
                deleteRequest.onsuccess = function() {
                    console.log("Save data cleared successfully.");
                    resolve();
                };
                deleteRequest.onerror = function() {
                    console.error("Failed to clear save data.");
                    reject();
                };
            };
        });
    };


    const handleAutoSave = useCallback(async (saveData) => {
        // console.log("Auto Save snapshot:", saveData);

        // Use IndexedDB for larger data storage
        try {
            await saveToIndexedDB(saveName, saveData);
            console.log("Game data saved successfully in IndexedDB!");
        } catch (error) {
            console.error("AutoSave error:", error);
        }
    }, []);

    useEffect(() => {
        checkSaveExists();
        addEventListener("AutoSave", handleAutoSave);
        return () => {
            removeEventListener("AutoSave", handleAutoSave);
        };
    }, [addEventListener, removeEventListener, handleAutoSave]);

    return (
        <>
            {
                (showContinuePrompt && saveExists) &&
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#ccc", padding: "20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}>
                    <p>Do you want to continue from where you left off?</p>
                    <button onClick={() => handleUserResponse(true)} style={{ marginRight: "10px" }}>Continue</button>
                    <button onClick={() => handleUserResponse(false)}>Start New</button>
                </div>
            }
        </>
    )
};

export { AutoSaveService };
