import React, { useState, useEffect } from 'react';
const ImageCarousel = ({ imageUrls }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // Handle arrow key navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        nextImage();
      } else if (event.key === 'ArrowLeft') {
        prevImage();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentIndex]);
  // Navigate to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };
  // Navigate to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };
  return (
    <div style={styles.container}>
      <img src={imageUrls[currentIndex]} alt={`Image ${currentIndex}`} style={styles.image} />
      <button onClick={prevImage} style={styles.prevButton}>⬅</button>
      <button onClick={nextImage} style={styles.nextButton}>➡</button>
    </div>
  );
};
// Basic inline styles
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#333',
    position: 'relative',
  },
  image: {
    maxWidth: '80%',
    maxHeight: '80%',
  },
  prevButton: {
    position: 'absolute',
    left: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '2rem',
    color: 'white',
    cursor: 'pointer',
  },
  nextButton: {
    position: 'absolute',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '2rem',
    color: 'white',
    cursor: 'pointer',
  },
};
export default ImageCarousel;