import { getUrl } from 'aws-amplify/storage';
import { useUnityContextProvider } from './UnityContext';
import { useEffect, useCallback} from 'react';

const GetAssetBundleURL = () => {
    const {sendMessage, addEventListener, removeEventListener} = useUnityContextProvider();

    const handleGetAssetBundleURL = useCallback(async (braceType) =>
      {
        var getURLResult = await getUrl({
          path: 'protected/encrypted_cut_braces/'+braceType+'/corsetgameobject',
          // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
          options: { expiresIn: 300 }
        });

        console.log(getURLResult.url.toString());
        sendMessage('AssetBundleLoader', 'DownloadAssetBundleFromAWS', getURLResult.url.toString());
      });

    useEffect(() => {
      addEventListener("GetAssetBundleURL", handleGetAssetBundleURL);
      return () => {
        removeEventListener("GetAssetBundleURL", handleGetAssetBundleURL);
      };
    }, [addEventListener, removeEventListener, handleGetAssetBundleURL]);
  };

  export {GetAssetBundleURL};