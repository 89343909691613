    // GetSamplePatients.js
import React from 'react';
import { useUnityContextProvider } from './UnityContext';
import { list } from 'aws-amplify/storage';
import '@aws-amplify/ui-react/styles.css';
const GetSamplePatients = () => {
  const { sendMessage } = useUnityContextProvider();
  const HandleGetSamplePatients = async (event) => {
    const response = await list({path: `protected/training_patients/`}); 
    var toBeSentElements = "";
    for (var i = 0; i <= response.items.length - 1; i++)
    {
        if ((response.items[i].path.split('/').length - 1 == 3) && (response.items[i].path.split('/')[3] == ""))
          toBeSentElements = toBeSentElements + response.items[i].path.split('/')[2] + "/";
    }
    sendMessage("Design", "GetPatientNames", toBeSentElements.slice(0,-1));
};
  return (
    <div>
      <button
        id="triggerGetSamplePatients"
        onClick={HandleGetSamplePatients}
        style = {{display: 'none'}}
      />
    </div>
  );
};
export default GetSamplePatients;
