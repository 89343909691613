// UploadXRay.js
import React from 'react';
import { useUnityContextProvider } from './UnityContext';

const UploadXRay = () => {
  const { sendMessage } = useUnityContextProvider();

  const handleXRaySelect = (event) => {
    const file = event.target.files[0]; // Get the first file
    
    if (file != null) {
      sendMessage('ModelImporter', 'LoadNewXRay', URL.createObjectURL(file));
    }
};

  return (
    <div>
      <input
        type="file"
        id="xrayInput"
        accept="image/*"
        multiple
        onChange={handleXRaySelect}
        style = {{display: 'none'}}
      />
    </div>
  );
};

export default UploadXRay;
