var DataStorage = null;

// A class for storing and retrieving data from storage
export class DataStorageService {
    constructor() {
        if (DataStorage == null) {
            DataStorage = this;
            this.data = {};
        }

        DataStorage = this;
    }

    // Stores data in storage
    storeData(key, value) {
        this.data[key] = value;
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    // Retrieves data from storage
    getData(key) {
        const data = sessionStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }

    // Removes data from storage
    removeData(key) {
        delete this.data[key];
        sessionStorage.removeItem(key);
    }

    // Clears all data from storage
    clearData() {
        this.data = {};
        sessionStorage.clear();
    }

    setCobbData(value) {
        this.storeData('cobbData', value);
    }

    // Set brace prediction data
    setBraceTypePredictionData(value) {
        this.storeData('braceTypePredictData', value);
    }

    // Set bone age data
    setBoneAgeData(value) {
        this.storeData('boneAgeData', value);
    }

    // Get brace prediction data
    getBraceTypePredictionData() {
        return this.getData('braceTypePredictData');
    }

    // Get bone age data
    getBoneAgeData() {
        return this.getData('boneAgeData');
    }

    // Get cobb data
    getCobbData() {
        return this.getData('cobbData');
    }
}

DataStorage = new DataStorageService();
export default DataStorage;
