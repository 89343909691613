import React, { useEffect, useRef } from 'react';
import { post} from 'aws-amplify/api';

function UserTimeTracker() {
  useEffect(() => {
    const interval = setInterval(() => {
      // Send session log request
      sendSessionLog();
    }, 1 * 60 * 1000); // Every 5 minutes (adjust as needed)

    // Send an initial session log when the component mounts
    sendSessionLog();

    return () => {
      clearInterval(interval);
      // Optionally, send a final session log when the component unmounts
      // sendSessionLog();
    };
  }, []);

  const sendSessionLog = async () => {
    try {
      const restOperation = post ({
        apiName: 'KimedixWebAppAPI',
        path: '/user/session/',
      });      
      console.log('Session log sent successfully');
    } catch (error) {
      console.error('Failed to send session log:', error);
    }
  };

  return null;
}

export default UserTimeTracker;
