import { get, put, post } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import awsconfig from '../aws-exports';
import { uploadFileToS3 } from './S3Utils';
import pako from 'pako';
import DataStorage from "../Services/DataStorageService";
import { getDateTimeNowWithUnderscore } from './Helper';
Amplify.configure(awsconfig, {
    API: {
      REST: {
        headers: async () => {
          const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
          return {
            Authorization: authToken
        };        }
      }
    }
  });

export async function getUserInfo() {
  try {
    const restOperation = get ({
      apiName: 'KimedixWebAppAPI',
      path: '/user/info/'
    });
    const response = await restOperation.response;
    const responseBody = await response.body.json();
    //console.log('GET call succeeded: ', response);
    //console.log(responseBody.user_data);
    return responseBody.user_data;
  } catch (e) {
    console.log('GET call failed: ', JSON.parse(e.response.body));
    return null;
  }
}
export async function getUserInfoButton() {
  try {
    const restOperation = get ({
      apiName: 'KimedixWebAppAPI',
      path: '/user/info/'
    });
    const response = await restOperation.response;
    const responseBody = await response.body.json();

  } catch (e) {
    console.log('GET call failed: ', JSON.parse(e.response.body));
  }
}

export async function ExpertDesignOrder(save) {
  try {
    // Create a File object using an array containing the Blob object
    const file1 = new File([new Blob([save])], "expert_design_order_save.json");


    const export_date = getDateTimeNowWithUnderscore();
    // Upload the files to S3
    await uploadFileToS3(file1, `orders/${export_date}/`);

    const credentials = await fetchAuthSession();

    const restOperation = post ({
      apiName: 'KimedixWebAppAPI',
      path: '/user/order/',
      options: {
        body: {"order_folder_path": `orders/${export_date}/`, "identityId" : `${credentials.identityId}`, "order_operation" : 'expert_design'}
      }
    });
    const response = await restOperation.response;
    const result = await response.body.json();

    return [result, response.statusCode];
  }
  catch (e) {
    console.log('Failed export: ', e);
    return [null, 400];
  }
}

export async function exportDesignURL(brace, save) {
  try {
    // Create a File object using an array containing the Blob object
    const file1 = new File([new Blob([atob(brace)])], "brace.obj");
    const file2 = new File([new Blob([save])], "save.json");


    const export_date = getDateTimeNowWithUnderscore();
    // Upload the files to S3
    await uploadFileToS3(file1, `exports/${export_date}/`);
    await uploadFileToS3(file2, `exports/${export_date}/`);

    const credentials = await fetchAuthSession();

    const restOperation = post ({
      apiName: 'KimedixWebAppAPI',
      path: '/user/export/',
      options: {
        body: {"export_folder_path": `exports/${export_date}/`, "identityId" : `${credentials.identityId}`}
      }
    });
    const response = await restOperation.response;
    const result = await response.body.json();

    return [result, "success"];
  }
  catch (e) {
    console.log('Failed export: ', e);
    return [e, "error"];
  }
}

export async function postBoneAgeAIRequest(base64Image){
  return await AIImageRequest(base64Image,"boneAgeAI");
}

export async function postCobbAIRequest(image){
  return await AIImageRequest(image, "cobbAI");
}

export async function AIImageRequest(image, operation) {
  try {

      const credentials = await fetchAuthSession();
      const  data = {"input_file" : image, "identityId" : `${credentials.identityId}` };

      const restOperation = post({
        apiName: 'KimedixWebAppAPI',
        path: `/user/ai?operation-type=${operation}`,
        options: {
          headers: {
            "Accept" : "application/json",
            "Content-Type": "multipart/form-data",
          },
          body: data
        }
    });
      const response = await restOperation.response;
      const result = await response.body.json();

      return [result, response.statusCode];
  } 
  catch (e) {
    console.log('POST call failed: ', e);
    return [null, 400];
  }
}

export async function postBraceTypePredictAIRequest(obj) {
  const compressedBase64String = compressBase64String(obj);

  return await Ai3DModelRequest(compressedBase64String, "braceTypeAI");
}

function compressBase64String(base64String) {
  // Decode base64 string to get the original data
  const binaryString = atob(base64String);

  const binaryData = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }

  // Compress the decoded data
  const compressedData = pako.deflate(binaryData);

  // Encode the compressed data back to a base64 string in chunks
  const CHUNK_SIZE = 0x8000; // Arbitrary chunk size
  let compressedBase64String = '';
  for (let i = 0; i < compressedData.length; i += CHUNK_SIZE) {
    compressedBase64String += String.fromCharCode.apply(
      null,
      compressedData.subarray(i, i + CHUNK_SIZE)
    );
  }

  // Encode the string to base64
  return btoa(compressedBase64String);
}

export async function Ai3DModelRequest(compressedBase64String, operation) {
  try {
    const credentials = await fetchAuthSession();

    const restOperation = post({
      apiName: 'KimedixWebAppAPI',
      path: `/user/ai?operation-type=${operation}`,
      options: {
        headers: {
          "Accept" : "application/json",
        },
        body: JSON.stringify({ compressedData: compressedBase64String, "identityId" : `${credentials.identityId}` })
      }
  });

  const response = await restOperation.response;
  const result = await response.body.json();

  return [result, response.statusCode];
  } catch (e) {
    console.log('POST call failed: ', e);
    return [null, 400];
  }
}

export async function testpostBraceTypePredictAIRequest() {
  try {
    const restOperation = get ({
      apiName: 'KimedixWebAppAPI',
      path: '/user/info/'
    });
    const response = await restOperation.response;
    const responseBody = await response.body.json();

  } catch (e) {
    console.log('GET call failed: ', JSON.parse(e.response.body));
  }
}
