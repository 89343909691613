import { list } from 'aws-amplify/storage';
import { useUnityContextProvider } from './UnityContext';
import { useEffect, useCallback} from 'react';

const ListAvailableBraces = () => {
    const {sendMessage, addEventListener, removeEventListener} = useUnityContextProvider();

    const handleListAvailableBraces = useCallback(async () =>
      {
        var getListResult = await list({
          path: 'protected/encrypted_cut_braces/',
          options: {
            listAll: true
          }
        });

        var availableBraces = [];
        getListResult.items.forEach(element => {
          if(element.path.includes("/corsetgameobject")){
            availableBraces.push(element.path.replace('protected/encrypted_cut_braces/',"").replace("/corsetgameobject",""));
          }
        });
        sendMessage('ChartUIManager', 'GetAvailableBraceTypesFromWeb', availableBraces.join(' '));
      });

    useEffect(() => {
      addEventListener("ListAvailableBraces", handleListAvailableBraces);
      return () => {
        removeEventListener("ListAvailableBraces", handleListAvailableBraces);
      };
    }, [addEventListener, removeEventListener, handleListAvailableBraces]);
  };


  export {ListAvailableBraces};