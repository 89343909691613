import React, { Fragment } from "react";
import './ScoliosisExam.css';

function ScoliosisExam() {
    return (
        <Fragment>
            <div className="wrapper">
                <div className="header">
                    <a href="http://kimedix.de">
                        <img src={`${process.env.PUBLIC_URL}/img/kimedix-logo.png`} alt="Company Logo" className="logo"/>
                    </a>
                </div>
            </div>
            <hr style={{ width: "100%" }} />

            <div className="wrapper">
                <h2 className="reportHeader">Scoliosis Type Classification Training</h2>
                <p>You can access the document explaining the decision-making process for brace types to be used <a href="https://kimedix.de/docs/kimedix_brace_classification.pdf">here</a>.</p>

                <div className="form-container">
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSc30UGkSlKq02deXkweii47sZP_FYSgBzrVFXp-wf1Sv5meXQ/viewform?embedded=true"
                        width="1200"
                        height="600"
                        frameBorder="1"
                        marginHeight="0"
                        marginWidth="0"
                        title="Scoliosis Type Classification Training"
                    >
                        Loading…
                    </iframe>
                </div>
            </div>

            <footer>
                <div>
                    <p>To learn more about our scoliosis solutions, please visit our <a href="https://kimedix.de">website</a>.</p>
                </div>
            </footer>
        </Fragment>
    );
}

export default ScoliosisExam;
