// src/utils/S3Utils.js
import { uploadData, remove } from 'aws-amplify/storage';

export const listFiles = async (path) => {
  // Assuming there is a listData method or similar in your Amplify setup
  // If not, you might need to use Storage.list as shown in AWS Amplify docs
  // return await Storage.list(path);
  return []; // Placeholder: Implement actual list logic based on your setup
};

export const uploadFileToS3 = async (file, path) => {
  const uploadPath = ({identityId}) =>`private/${identityId}/${path}${file.name}`;
  console.log("uploading to "+ uploadPath);
  const result = await uploadData({
    path: uploadPath,
    data: file,

    options: {
      onProgress: ({ transferredBytes, totalBytes }) => {
        if (totalBytes) {
          console.log(
            `Upload progress ${
              Math.round((transferredBytes / totalBytes) * 100)
            } %`
          );
        }
      }
    }
  }).result;

  return result;
};

export const deleteFileFromS3 = async (path) => {
  // Assuming there is a deleteData method or similar
  // If not, you would typically use Storage.remove(path)
  // return await Storage.remove(path);
  remove(path);
  console.log('Delete file at:', path); // Placeholder: Implement actual delete logic
};
