// GetUserInfo.js
import React from 'react';
import { useUnityContextProvider } from './UnityContext';
import {getUserInfo} from '../utils/UserAPI.js';

const GetUserInfo = () => {
  const { sendMessage } = useUnityContextProvider();

  const HandleGetUserInfo = async (event) => {
    var userInfo = await getUserInfo();
    console.log(userInfo);

    sendMessage("Order", "GetUserName", userInfo.user_name + " " + userInfo.user_surname);
    sendMessage("Order", "GetUserEmail", userInfo.user_email);
    sendMessage("Order", "GetUserPhone", userInfo.user_phoneNumber);
    sendMessage("Order", "GetUserCompany", userInfo.user_clinicName);

    if (userInfo.user_activationDate != null) 
      sendMessage("Order", "GetUserActivationDate", userInfo.user_activationDate);

    else 
      sendMessage("Order", "GetUserActivationDate", "");

    sendMessage("Order", "GetUserToken", userInfo.user_token);
    sendMessage("Order", "GetUserExpertPrice", userInfo.price_expert_design);
    sendMessage("Order", "GetUserCustomerPrice", userInfo.price_design_export);
    sendMessage("Order", "GetUserTutorialPrice", userInfo.price_expert_design_with_video);
    sendMessage("TopNavigationMenuManager", "HandleGetUserInfoCompleted");
    sendMessage("SidebarManager", "HandleGetUserInfoCompleted");
    sendMessage("AISidebarManager", "HandleGetUserInfoCompleted");
};

  return (
    <div>
      <button
        id="triggerGetUserInfo"
        onClick={HandleGetUserInfo}
        style = {{display: 'none'}}
      />
    </div>
  );
};

export default GetUserInfo;
