// UnityContext.js
import React, { createContext, useContext } from 'react';

const UnityContext = createContext();

export const useUnityContextProvider = () => useContext(UnityContext);

export const UnityProvider = ({ children, value }) => (
  <UnityContext.Provider value={value}>
    {children}
  </UnityContext.Provider>
);
