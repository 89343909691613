import React, {Fragment, useEffect, useState} from 'react';
import './PredictionReport.css';
import {useLocation} from "react-router-dom";
import DataStorage from "../Services/DataStorageService";
import { Chart } from "react-google-charts";


function BraceTypePredictionReport() {
    const location = useLocation();
    const [reportData, setReportData] = useState({});
    const [result, setResult] = useState({});
    const [chartData, setChartData] = useState([]);
    const [bestFit, setBestFit] = useState([]);

    function processChartData(data) {
        const rows = [];
        rows.push(['Brace Type', 'Probability']);
        for (let i = 0; i < data.length; i++) {
            rows.push([`K${i+1}`, data[i]]);
        }
        return rows;
    }

    useEffect(() => {
        const response = DataStorage.getBraceTypePredictionData();
        if (response) {
            setReportData(response);
            const processedData = processChartData(response.brace_prediction_probs);
            setChartData(processedData);
            const filteredData = processedData.filter((a) => {
                if (typeof a[1] == typeof 'Probability') return false;
                return true; 
            })
            setBestFit(filteredData.reduce((max, item) => item[1] > max[1] ? item : max, filteredData[0]));
        }
    }, [location]);

    if (!reportData) {
        return <div>Loading...</div>;
    }

    // Download the report as a PDF file
    const handleSaveReport = () => {
        window.print();
    };

    const hasChartData = chartData.length > 1 && chartData[1].length > 1;

    return (
        <Fragment>
            <div className="wrapper">
                <div className="header">
                    <a href="http://kimedix.de">
                        <img src={`${process.env.PUBLIC_URL}/img/kimedix-logo.png`} alt="Company Logo"
                             className="logo"/>
                    </a>
                    <button onClick={handleSaveReport}>Save Report</button>
                </div>
            </div>
            <hr style={{width: "100%"}}/>

            <div className="wrapper">
                <h2 className="reportHeader">Brace Type Prediction Report</h2>
                <p>
                    Based on the provided data, the suitable brace type {hasChartData ? bestFit[0] : "unknown"} has been identified after analyzing the uploaded data for patient number/name xxxxxxx.
                </p>

                <div className="image-right">
                    <div>
                        <h4>HEATMAP CONVERSION</h4>
                        <p>
                            3D of the human body is converted into a heatmap. The heatmap visually represents the asymmetry distribution across different parts of the body, typically denoted by color gradients.
                        </p>
                    </div>
                    <img src={`data:image/png;base64,${reportData.heatmap_image}`} alt="Roi Image" className="logo"/>
                </div>

                <div className="image-left">
                    <Chart
                        chartType="PieChart"
                        data={chartData}
                        options={{title: "Brace Type Distribution"}}
                        width={"80%"}
                        height={"300px"}
                    />
                    <div>
                        <h4>BRACE PREDICTION FROM HEATMAP</h4>
                        <p>
                            Following the conversion to a heatmap, the system proceeds to predict the suitable brace type for the individual. This prediction is based on analyzing the heatmap data to identify areas that may require support or correction and then determining the type of brace that best addresses those needs.
                        </p>
                    </div>
                </div>

                {hasChartData && (
                    <div className="results">
                        <h2>{bestFit[0]}</h2>
                        <p>The {bestFit[0]} corset is indicated with a probability of {(bestFit[1] * 100).toFixed(2)}%.</p>
                    </div>
                )}
            </div>
            <footer>
                <div>
                    <p>To learn more about our AI model, please visit our <a href="https://kimedix.de">website</a>.</p>
                </div>
            </footer>
        </Fragment>
    );
}

export default BraceTypePredictionReport;