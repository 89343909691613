import React from 'react';
import { useUnityContextProvider } from './UnityContext';

const FullscreenButton = () => {
  const { requestFullscreen } = useUnityContextProvider();
  
  const OnClickFullScreenButton = () => {
    requestFullscreen (true);
  };

  return(
    <button
      id="unity-fullscreen-button"
      onClick={OnClickFullScreenButton}
      style={{
        cursor: 'pointer',
        float: 'right',
        width: '38px',
        height: '38px',
        background: `url(${process.env.PUBLIC_URL}/unity-build/TemplateData/fullscreen-button.png) no-repeat center`,
      }}
    />
  )
};

export {FullscreenButton};