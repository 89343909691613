import React from 'react';
import { postBoneAgeAIRequest } from '../utils/UserAPI';

const UploadXRayForBoneAge = () => {

  const handleXRaySelect = (event) => {
    const file = event.target.files[0];
    const customEvent = new CustomEvent("uploadXRayForBoneAge", { detail: file });
    window.dispatchEvent(customEvent);
  };

  return (
    <div>
      <input
        type="file"
        id="fileInputBoneAge"
        accept="image/*"
        onChange={handleXRaySelect}
        style = {{display: 'none'}}
      />
    </div>
  );
};

export default UploadXRayForBoneAge;
