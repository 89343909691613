import React from 'react';
import { useUnityContextProvider } from './UnityContext';
import { postCobbAIRequest } from '../utils/UserAPI';

const PostImageCobbAI = () => {
  const { sendMessage } = useUnityContextProvider();

  const handlePostImage = (event) => {
    const file = event.target.files[0]; // Get the first file
    const response = postCobbAIRequest(file);
    if(response != null)
      sendMessage('CobbCalculation', 'ProcessModelOutput', URL.createObjectURL(response));
  };

  return (
    <div>
      <button
        id="uploadImageForCobb"
        onClick={handlePostImage}
        style = {{display: 'none'}}
      />
    </div>
  );
};

export default PostImageCobbAI;