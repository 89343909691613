// UploadScan.js
import React from 'react';
import { useUnityContextProvider } from './UnityContext';

const UploadScan = () => {
  const { unityProvider, sendMessage } = useUnityContextProvider();

  const HandleScanSelect = (event) => {
    const files = event.target.files; // Get all files

    if (files.length > 0) {
      sendMessage('ModelImporter', 'PrepareForNewModel');
      const totalFiles = files.length;
      let processedFiles = 0;

      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
          const arrayBuffer = evt.target.result;
          const uintArray = new Uint8Array(arrayBuffer);
          const chunks = [];
          for (let i = 0; i < uintArray.length; i += 0x8000) {
            chunks.push(uintArray.subarray(i, i + 0x8000));
          }
          if (unityProvider) {
            // Send the chunks one by one to Unity
            for (let j = 0; j < chunks.length; j++) {
              const base64String = btoa(String.fromCharCode.apply(null, chunks[j]));
              const combinedString = file.name + "|:|" + base64String;
              sendMessage('ModelImporter', 'ReceiveFileDataChunk', combinedString);
            }
            // Signal the end of transmission for each file
            sendMessage('ModelImporter', 'ReceiveFileDataComplete', file.name);
          } else {
            console.log("Unity instance not ready");
          }

          processedFiles++;
          if (processedFiles === totalFiles) {
            // All files are processed, now you can signal Unity that all files are sent
            sendMessage('ModelImporter', 'AllFilesReceived');
          }
        };
        reader.readAsArrayBuffer(file);
      });
    }
  };

  return (
    <div>
      <input
        type="file"
        id="fileInput"
        accept=".obj,.mtl,image/*"
        multiple
        onChange={HandleScanSelect}
        style = {{display: 'none'}}
      />
    </div>
  );
};

export default UploadScan;
