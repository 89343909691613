import React, {Fragment, useEffect, useState} from 'react';
import './PredictionReport.css';
import {useLocation} from "react-router-dom";
import DataStorage from "../Services/DataStorageService";


function BoneAgeReport() {
    const location = useLocation();
    const [reportData, setReportData] = useState(null);
    const [result, setResult] = useState({});

    const findPredictions = (obj, key, result = []) => {
        // If the object is an array, iterate over each element
        if (Array.isArray(obj)) {
            for (let item of obj) {
                findPredictions(item, key, result);
            }
        }
        // If the object is an object, iterate over each key-value pair
        else if (typeof obj === 'object' && obj !== null) {
            for (let k in obj) {
                if (k === key) {
                    result.push(obj[k]);
                }
                findPredictions(obj[k], key, result);
            }
        }
        return result;
    };


    useEffect(() => {
        const response = DataStorage.getBoneAgeData();

        if (response) {
            setReportData(response);
        }

        let predictions = [];
        findPredictions(response, 'prediction', predictions);

        const mean = predictions.reduce((acc, val) => acc + val, 0) / predictions.length;
        const stdDev = Math.sqrt(predictions.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / predictions.length);

        setResult({
            mean: mean.toFixed(2),
            stdDev: stdDev.toFixed(2),
        });
    }, [findPredictions, location]);

    if (!reportData) {
        return <div>Loading...</div>;
    }

    // Download the report as a PDF file
    const handleSaveReport = () => {
        window.print();
    };

    return (
        <Fragment>
            <div className="wrapper">
                <div className="header">
                    <a href="http://kimedix.de">
                        <img src={`${process.env.PUBLIC_URL}/img/kimedix-logo.png`} alt="Company Logo"
                             className="logo"/>
                    </a>
                    <button onClick={handleSaveReport}>Save Report</button>
                </div>
            </div>
            <hr style={{width: "100%"}}/>

            <div className="wrapper">
                <h2 className="reportHeader">General Age Estimation Report</h2>
                <p>
                    Based on the provided bone data, an approximate general age of {result.mean} months has been
                    determined after analyzing the uploaded data for patient number/name xxxxxxx.
                </p>

                <div className="image-right">
                    <div>
                        <h4 className="v1148_22803">ROI DETECTION FROM XRAY</h4>
                        <p>
                            Our AI model employs Region of Interest (ROI) detection techniques on X-ray images,
                            pinpointing
                            specific anatomical landmarks.
                        </p>
                    </div>
                    <img src={`data:image/png;base64,${reportData.mask}`} alt="Roi Image" className="logo"/>
                </div>

                <div className="image-left">
                    <img src={`data:image/png;base64,${reportData.all_roi}`} alt="Roi Image" className="logo"/>
                    <div>
                        <h4 className="v1148_22803">ROI DETECTION FROM XRAY</h4>
                        <p>
                            Our AI model employs Region of Interest (ROI) detection techniques on X-ray images,
                            pinpointing
                            specific anatomical landmarks.
                        </p>
                    </div>
                </div>
                <div className="results">
                    <h2>{result.mean } ± {result.stdDev} MONTHS</h2>
                    <p>Our model yields a general age prediction.</p>
                </div>
            </div>
            <footer>
                <div>
                    <p>To learn more about our AI model, please visit our <a href="https://kimedix.de"> website</a>.</p>
                </div>
            </footer>
        </Fragment>
    );
}

export default BoneAgeReport;