// src/SplashScreenController.js
import { useUnityContextProvider } from './UnityContext';
import { useEffect, useCallback } from 'react';
import { useGlobalState } from '../GlobalStateContext';

const SplashScreenController = () => {
    const { setSplashScreenOver } = useGlobalState();
    const { addEventListener, removeEventListener } = useUnityContextProvider();

    const handleEnableSplashScreen = useCallback(() => {
        console.log("Enable Splash Screen called.");
        setSplashScreenOver(false);
    }, [setSplashScreenOver]);

    const handleDisableSplashScreen = useCallback(() => {
        console.log("Disable Splash Screen called.");
        setSplashScreenOver(true);
    }, [setSplashScreenOver]);

    useEffect(() => {
        addEventListener("EnableSplashScreen", handleEnableSplashScreen);
        addEventListener("DisableSplashScreen", handleDisableSplashScreen);

        return () => {
            removeEventListener("EnableSplashScreen", handleEnableSplashScreen);
            removeEventListener("DisableSplashScreen", handleDisableSplashScreen);
        };
    }, [addEventListener, removeEventListener, handleEnableSplashScreen, handleDisableSplashScreen]);
};

export { SplashScreenController };
