import {useEffect} from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import BoneAgeReport from './components/BoneAgePredictionReport.js';
import CobbReport from './components/CobbReport.js';
import {postBoneAgeAIRequest, postCobbAIRequest, exportDesignURL, postBraceTypePredictAIRequest, ExpertDesignOrder} from "./utils/UserAPI";
import {useUnityContext} from "react-unity-webgl";
import UnityApp from './components/UnityApp.js';
import * as PropTypes from "prop-types";
import {UnityProvider} from "./UnityServices/UnityContext";
import DataStorage from "./Services/DataStorageService";
import BraceTypePredictionReport from "./components/BraceTypePredictionReport";
import ScoliosisExam from "./components/ScoliosisExam";
import { GlobalStateProvider } from './GlobalStateContext';
import { list, getUrl  } from 'aws-amplify/storage';
import ImageCarousel from './components/ImageCarousel.js';
import ReactDOM from 'react-dom/client';    
Amplify.configure(awsconfig);

UnityProvider.propTypes = {
    value: PropTypes.shape({
        requestFullscreen: PropTypes.func,
        removeEventListener: PropTypes.func,
        unityProvider: PropTypes.any,
        loadingProgression: PropTypes.number,
        sendMessage: PropTypes.func,
        isLoaded: PropTypes.bool,
        addEventListener: PropTypes.func
    }),
    children: PropTypes.node
};

function App({ signOut }) {

    const {
        unityProvider,
        isLoaded,
        loadingProgression,
        sendMessage,
        requestFullscreen,
        addEventListener,
        removeEventListener,
    } = useUnityContext({
        loaderUrl: "../unity-build/Build/unity-build.loader.js",
        dataUrl: "../unity-build/Build/unity-build.data",
        frameworkUrl: "../unity-build/Build/unity-build.framework.js",
        codeUrl: "../unity-build/Build/unity-build.wasm",
    });

    const unityContextValue = {
        unityProvider,
        isLoaded,
        loadingProgression,
        sendMessage,
        requestFullscreen,
        addEventListener,
        removeEventListener,
    };


    const handleExportProject = async (event) => {
        const brace = event.detail.brace;
        const save = event.detail.save;
  
        try {
            const response = await exportDesignURL(brace, save);
            console.log(response);
            if (response[1] === "success") {
                sendMessage("TopNavigationMenuManager", "HandleExportCompleted", "success");
            }
            if (response[1] === "error") {
                sendMessage("TopNavigationMenuManager", "HandleExportCompleted", "error");
            }
        } catch (error) {
            console.error('Error in export request:', error);
            sendMessage("TopNavigationMenuManager", "HandleExportCompleted", "error");
        }
    };
  

    const handlePostScan = async (event) => {
        const obj = event.detail;
  
        try {
            const response = await postBraceTypePredictAIRequest(obj);
            if (response[1] === 200) {
                DataStorage.setBraceTypePredictionData(response[0]);
                const popup = window.open(`/brace-prediction`, "_blank");

                if (!popup || popup.closed || typeof popup.closed === 'undefined') { 
                    alert("Popup was blocked! Please allow popups for this site. After allowing popups, please click the 'OK' to view the report.");
                    window.open(`/brace-prediction`, "_blank");
                }

            }
            sendMessage("AISidebarManager","GetBraceTypePredictResponse", response[1]);
        } catch (error) {
            console.error('Error in AI request:', error);
        }
    }

    const handleScoliosisExam = async (event) => {
        try {

            const popup = window.open(`/scoliosis-exam`);
            if (!popup || popup.closed || typeof popup.closed === 'undefined') { 
                alert("Popup was blocked! Please allow popups for this site. After allowing popups, please click the 'OK' to view the report.");
                window.open(`/scoliosis-exam`, "_blank");
            }
        } 
        catch (error) {
            console.error('Error opening scoliosis exam:', error);
        }
    }

    const handlePostImage = async (event) => {
        const image = event.detail;

        try {
            const response = await postCobbAIRequest(image);
            if (response) {
                sendMessage(
                    "CobbCalculation",
                    "ProcessModelOutput",
                    JSON.stringify(response[0])
                );
            }
        } catch (error) {
            console.error("Error in AI request:", error);
            sendMessage("CobbCalculation", "FailedRequest", JSON.stringify(error));
        }
    };

    const handleSendReport = async (event) => {
        let report = event.detail;
        DataStorage.setCobbData(report);
        const popup = window.open(`/cobb-report`, "_blank");
        if (!popup || popup.closed || typeof popup.closed === 'undefined') { 
            alert("Popup was blocked! Please allow popups for this site. After allowing popups, please click the 'OK' to view the report.");
            window.open(`/cobb-report`, "_blank");
        }
    };

    const handleBoneAgeAIRequest = async (event) => {
        const file = event.detail;
        if (file != null) {
            try {
            const reader = new FileReader();
            reader.onloadend = async () => {
                sendMessage("AISidebarManager" , "GetBoneAgeAIResponse", 1);
                const base64Image = reader.result.split(",")[1];
                const response = await postBoneAgeAIRequest(base64Image);
                // Wrap the response in a JSON string and encode it
                const responseString = encodeURIComponent(JSON.stringify(response[0]));
                DataStorage.setBoneAgeData(response[0]);
                const popup = window.open(`/bone-age-measurement`, "_blank");
                if (!popup || popup.closed || typeof popup.closed === 'undefined') { 
                    alert("Popup was blocked! Please allow popups for this site. After allowing popups, please click the 'OK' to view the report.");
                    window.open(`/bone-age-measurement`, "_blank");
                }
                sendMessage("AISidebarManager", "GetBoneAgeAIResponse", response[1]);
            }

            reader.readAsDataURL(file);
            }

            catch 
            {
                sendMessage("AISidebarManager", "GetBoneAgeAIResponse", 501);
            }
        }
    };
    
    const handleSendExpertDesignOrder = async (event) => {
        const save = event.detail;
  
        try {
            const response = await ExpertDesignOrder(save);
            sendMessage("SidebarManager","HandleUploadComplete", response[1]);
        } catch (error) {
            console.error('Error in Upload Save request:', error);
            sendMessage("SidebarManager","HandleUploadComplete", 400);
        }
    };

    const handleSignOut = () => 
    {
        signOut();
    }
    const handleShowSamplePatient = async (event) =>
        {
            const selectedSamplePatient = event.detail.patientName;
            var path = "protected/training_patients/" + selectedSamplePatient + "/images/";
            const response = await list({path: path});
            const imageUrls = [];
            for (var i = 1; i < response.items.length; i++)
            {
                const getUrlResult = await getUrl({
                    path: response.items[i].path,
                    // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
                    options: {
                      expiresIn: 1200 // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
                    },
                  });
                imageUrls.push(getUrlResult.url.toString());
            }
            const newWindow = window.open();
            const newDocument = newWindow.document;
            console.log("test");
            newDocument.write(`
              <!DOCTYPE html>
              <html lang="en">
                <head>
                  <meta charset="UTF-8" />
                  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                  <title>Image Carousel</title>
                </head>
                <body>
                  <div id="root"></div>
                </body>
              </html>
            `);
        
            newDocument.close();
        
            // Render the React ImageCarousel component into the new window
            ReactDOM.createRoot(newWindow.document.getElementById('root')).render(
              <ImageCarousel imageUrls={imageUrls} />
            );
        }
        const handleDownloadSamplePatient = async (event) =>
        {
            const selectedSamplePatient = event.detail.patientName;
            var path = "protected/training_patients/" + selectedSamplePatient + "/patientsave.json";
            const getUrlResult = await getUrl({
                path: path,
                // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
                options: {
                  expiresIn: 120 // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
                },
            });
            sendMessage("SaveLoadManager","LoadSamplePatientSave", getUrlResult.url.toString());
        }
    useEffect(() => {
        window.addEventListener("uploadImageForCobb", handlePostImage);
        window.addEventListener("sendReportCobbAI", handleSendReport);
        window.addEventListener("uploadXRayForBoneAge", handleBoneAgeAIRequest);
        window.addEventListener('exportProject', handleExportProject);
        window.addEventListener('uploadScanForBraceTypePredictAI', handlePostScan);
        window.addEventListener('openScoliosisExam', handleScoliosisExam);
        window.addEventListener('sendExpertDesignOrder', handleSendExpertDesignOrder);
        window.addEventListener('SignOut', handleSignOut);
        window.addEventListener('showSamplePatient', handleShowSamplePatient);
        window.addEventListener('downloadSamplePatient', handleDownloadSamplePatient)

        return () => {
            window.removeEventListener("uploadImageForCobb", handlePostImage);
            window.removeEventListener("sendReportCobbAI", handleSendReport);
            window.removeEventListener("uploadXRayForBoneAge", handleBoneAgeAIRequest);
            window.removeEventListener('uploadScanForBraceTypePredictAI', handlePostScan);
            window.removeEventListener('openScoliosisExam', handleScoliosisExam);
            window.removeEventListener('exportProject', handleExportProject);
            window.removeEventListener('sendExpertDesignOrder', handleSendExpertDesignOrder);
            window.removeEventListener('signOut', handleSignOut);
            window.removeEventListener('showSamplePatient', handleShowSamplePatient);
            window.removeEventListener('downloadSamplePatient', handleDownloadSamplePatient);
        };
    }, [addEventListener, removeEventListener, handlePostImage, handleSendReport, handleExportProject,
        handlePostScan, handleSendExpertDesignOrder, handleSignOut, handleShowSamplePatient,handleDownloadSamplePatient]);

    return (
        <GlobalStateProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element = {<UnityApp unityContextValue={unityContextValue}/>}/>
                    <Route path="/bone-age-measurement" element = {<BoneAgeReport />}/>
                    <Route path="/brace-prediction" element = {<BraceTypePredictionReport />}/>
                    <Route path="/cobb-report" element = {<CobbReport />}/>
                    <Route path="/scoliosis-exam" element = {<ScoliosisExam />}/>
                </Routes>

            </BrowserRouter>
        </GlobalStateProvider>
    );
}

export default withAuthenticator(App);