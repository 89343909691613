import React, {Fragment, useEffect, useState} from 'react';
import './PredictionReport.css';
import {useLocation} from "react-router-dom";
import DataStorage from "../Services/DataStorageService";


function CobbReport() {
    const location = useLocation();
    const [reportData, setReportData] = useState(null);

    useEffect(() => {
        const response = DataStorage.getCobbData();
        console.log("Response from component");
        if (response) {
            setReportData(response);
        }
    }, [location]);

    if (!reportData) {
        return <div>Loading...</div>;
    }

    // Download the report as a PDF file
    const handleSaveReport = () => {
        window.print();
    };

    return (
        <Fragment>
            <div className="wrapper">
                <div className="header">
                    <a href="http://kimedix.de">
                        <img src={`${process.env.PUBLIC_URL}/img/kimedix-logo.png`} alt="Company Logo"
                             className="logo"/>
                    </a>
                    <button onClick={handleSaveReport}>Save Report</button>
                </div>
            </div>
            <hr style={{width: "100%"}}/>

            <div className="wrapper">
                <h2 className="reportHeader">Cobb Calculations Report</h2>
                <p>
                    These are the report of the results collected from the Cobb Calculations for the patient number/name xxxxxxx.
                </p>

                <CalculationMethod methodName="Corner Points" images={reportData[0]}/>
                <CalculationMethod methodName="Center Line Curve" images={reportData[1]}/>
                <CalculationMethod methodName="Center Line Distance" images={reportData[2]}/>
            </div>

            <footer>
                <div>
                    <p>To learn more about our AI model, please visit our <a href="https://kimedix.de"> website</a>.
                    </p>
                </div>
            </footer>
        </Fragment>
    );
}

function CalculationMethod({methodName, images}) {
    return (images.length > 0 ?
        <div className="Calculations">
            <h2 style={{textAlign: 'center'}}> {methodName} Method </h2>
            <div className="cobb-images">
                {images.map((item, index) => (
                    <div key={index}>
                        <img src={`data:image/png;base64,${item}`} alt={`Cobb Calculation ${index}`}/>
                    </div>
                ))}
            </div>
        </div> : <div></div>);
}

export default CobbReport;