// ImportSave.js
import React from 'react';
import { useUnityContextProvider } from './UnityContext';

const LoadAdditionalData = () => {
  const { sendMessage } = useUnityContextProvider();

  const handleLoadAdditionalData = (event) => {
    const file = event.target.files[0]; // Get the first file
    if (file) {
      sendMessage('AdditionalDataManager', 'GetURLFromReact', URL.createObjectURL(file));
    }
};

  return (
    <div>
      <input
        type="file"
        id="loadAdditionalData"
        accept="image/*"
        multiple
        onChange={handleLoadAdditionalData}
        style = {{display: 'none'}}
      />
    </div>
  );
};

export default LoadAdditionalData;
