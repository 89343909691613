// ImportSave.js
import React from 'react';
import { useUnityContextProvider } from './UnityContext';

const ImportSave = () => {
  const { sendMessage } = useUnityContextProvider();

  const handleSaveFileSelect = (event) => {
    const file = event.target.files[0]; // Get the first file
    if (file) {
      sendMessage('SaveLoadManager', 'GetJSONFileURL', URL.createObjectURL(file));
    }
};

  return (
    <div>
      <input
        type="file"
        id="saveInput"
        accept=".json"
        multiple
        onChange={handleSaveFileSelect}
        style = {{display: 'none'}}
      />
    </div>
  );
};

export default ImportSave;
