// src/GlobalStateContext.js
import React, { createContext, useState, useContext } from 'react';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [splashScreenOver, setSplashScreenOver] = useState(false);

    return (
        <GlobalStateContext.Provider value={{ splashScreenOver, setSplashScreenOver }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = () => useContext(GlobalStateContext);
